<script>
import {
    Carousel,
    Slide
} from 'vue-carousel';
import {
    ArrowUpIcon,
    VideoIcon
} from 'vue-feather-icons';

import Navbar from "@/components/navbar";
import Footer from "@/components/footer";

/**
 * Index-classic-app component
 */
export default {
    data() {
        return {
        }
    },
    components: {
        Navbar,
        Footer,
        Carousel,
        Slide,
        ArrowUpIcon,
        VideoIcon,
    }
}
</script>

<template>
<div>
    <Navbar />
    <!-- Hero Start -->
    <section class="bg-half-170 d-table w-100 overflow-hidden" id="home">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-7">
                    <div class="title-heading mt-4">
                        <h1 class="heading mb-3">Boost your online direct bookings, get your official website with one click</h1>
                        <p class="para-desc text-muted">Take your business online in just a few steps. Our Booking Engine can get your products online and make them bookable in one sec.</p>
                        <div class="watch-video mt-4 pt-2">
                            <a href="https://new.treep.cc/#/light-openTrial"
                            target="_blank"
                            class="btn btn-primary"
                            >Start free trial</a>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
                    <div class="classic-app-image position-relative">
                        <div class="bg-app-shape position-relative">
                            <img src="images/booking/1_1.png" class="mover mx-auto d-block" height="640" alt="">
                        </div>
                        <div class="app-images d-none d-md-block">
                            <img src="images/booking/1calendar_1.png" height="200" alt="">
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Section Start -->
    <section class="section overflow-hidden">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <div class="section-title mb-4 pb-2">
                        <span class="badge badge-pill badge-primary mb-2">Features</span>
                        <h4 class="title mb-4">How it works</h4>
                        <p class="text-muted para-desc mx-auto mb-0">Whenever update your products in <span class="text-primary font-weight-bold">TREEP</span>, your website update too. </p>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->

            <div class="row">
                <div class="col-md-4 col-12 mt-4 pt-2">
                    <div class="card text-center rounded border-0">
                        <div class="card-body">
                            <div class="p-3 bg-light rounded shadow d-inline-block">
                                <img src="images/template/art-and-design.svg" class="avatar avatar-small" alt="">
                            </div>
                            <div class="mt-4">
                                <h5><a href="javascript:void(0)" class="text-dark">Set up products, price & availability</a></h5>
                                <p class="text-muted mt-3 mb-0">Easily list all of your products and manage the different pricing options and start times. Your availability will sync across all of your sales channels to ensure you're never overbooked.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-4 col-12 mt-4 pt-2">
                    <div class="card text-center bg-primary rounded border-0">
                        <div class="card-body">
                            <div class="p-3 bg-white rounded shadow d-inline-block">
                                <img src="images/template/smartphone.svg" class="avatar avatar-small" alt="">
                            </div>
                            <div class="mt-4">
                                <h5><a href="javascript:void(0)" class="text-white">Sell online and take payments</a></h5>
                                <p class="text-white-50 mt-3 mb-0">Let TREEP customize your website or simply use TREEP booking widget to start selling online. Secure payment through our integrated payment gateway providers anytime, anywhere.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-4 col-12 mt-4 pt-2">
                    <div class="card text-center rounded border-0">
                        <div class="card-body">
                            <div class="p-3 bg-light rounded shadow d-inline-block">
                                <img src="images/template/clock.svg" class="avatar avatar-small" alt="">
                            </div>
                            <div class="mt-4">
                                <h5><a href="javascript:void(0)" class="text-dark">Run your day-to-day operations</a></h5>
                                <p class="text-muted mt-3 mb-0">Oversee all your upcoming bookings and passenger info, automatically pass it to your guides and drivers. Collaborate with all depatments in TREEP.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->

        <div class="container mt-100 mt-60">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-5">
                    <div class="app-feature-shape-left position-relative">
                        <div class="text-center text-md-left">
                            <img src="images/booking/2_1.png" height="640" alt="">
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-6 col-md-7 mt-5 mt-sm-0">
                    <div class="section-title">
                        <h1 class="title mb-3">
                            Struggle to  build your own website ? <br />
                            Customize an <span class="text-primary font-weight-bold">Official Website</span> <span class="font-weight-bold">with TREEP</span> </h1>
                        <p class="para-desc text-muted">Your guests can easily browse, book and pay,  seize all potential bookings. Reduce the time wasted on the phone or E-mail to confirm bookings for the guest.</p>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->

        <div class="container mt-100 mt-60">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-7 order-2 order-md-1 mt-5 mt-sm-0">
                    <div class="section-title">
                        <h1 class="title mb-3">
                            Already have a website? <br />
                            Add a call to action button with a <span class="text-primary font-weight-bold">Booking Widget</span>
                        </h1>
                        <p class="para-desc text-muted">Booking Widgets are the easiest way to make any website instantly bookable. If you have existing website but can't take bookings online,  it would be a great booster for your direct booking. </p>
                        <ul class="list-unstyled text-muted">
                            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uil uil-check-circle align-middle"></i></span>Choose from a number of different options like booking buttons, product lists and booking calendars, generate the embed code and add it to your site. </li>
                            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uil uil-check-circle align-middle"></i></span>It integrates with payment gateway providers to secure your bookings and payments.</li>
                        </ul>
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-6 col-md-5 order-1 order-md-2">
                    <div class="app-feature-shape-right position-relative">
                        <div class="text-center text-md-right">
                            <img src="images/booking/3_1.png" height="640" alt="">
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Section End -->

    <!-- Subscribe Start -->
    <section class="section bg-primary">
        <div class="bg-overlay bg-overlay-white"></div>
        <div class="container position-relative">
            <div class="row">
                <div class="col-lg-5 col-md-6">
                    <div class="app-subscribe text-center text-md-left">
                        <img src="images/booking/4_1.png" height="640" alt="">
                    </div>
                </div>
                <!--end col-->

                <div class="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                    <div class="section-title text-center text-md-left">
                        <h1 class="title text-white title-dark mb-2">Take online bookings now, seize all booking opportunities</h1>
                        <p class="text-light para-dark">Our Booking Engine is the easiest way to get your products online and make them bookable. </p>
                    </div>
                    <div class="watch-video mt-4 pt-2">
                            <a href="https://new.treep.cc/#/light-openTrial" target="_blank" class="btn" 
                            :class="{
                                'btn-light': navLight === true,
                                'btn-primary': navLight !== true,
                            }"
                            >Start free trial</a>
                        </div>
                    <!--end subscribe form-->
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <!-- Subscribe End -->
    <!-- Section End -->
    <Footer />
    <!-- Footer End -->
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>

<style>
.modal-content {
    background-color: transparent;
}
</style>
